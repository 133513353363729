<template>
    <div class="campus-health">
        <heads></heads>
        <img class="top-bg" src="../../assets/images/campus_health_top_bg.png" alt="">
        <div class="campus-health-container">
            <div class="content">
                <div class="introduce">
                    <p>{{ articleCategory.description }}</p>
                </div>

                <div class="navs" v-if="articleCategory.children.length > 0">
                    <div class="navs-item" v-for="(item, index) in articleCategory.children">
                        <router-link :to="item.link_url != '' && item.link_url != null ? item.link_url : {
                            path: '/campusHealth/category',
                            query: { id: item.id }
                        }">
                            <img :src="item.icon" alt="">
                            <span class="title">{{ item.name }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getArticleCategory } from "@api/public";
export default {
    components: {
        heads,
        foot,
    },
    data() {
        return {
            articleCategory: { children: [] }
        };
    },
    mounted: function () {
        let that = this;
        getArticleCategory({ id: 11 }).then(function (res) {
            that.$set(that, "articleCategory", res.data);
        });
    },
    created: function () {
        document.title = this.$route.meta.title;
    },
};
</script>

<style scoped>
.campus-health {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #3a657d;
}

.campus-health .top-bg {
    width: 1920px;
    height: 470px;
}

.campus-health .campus-health-container {
    position: relative;
    width: 1920px;
    height: auto;
    top: -205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content {
    position: relative;
    width: 960px;
    height: auto;
    display: flex;
    flex-direction: column;
}


.campus-health .campus-health-container .content .introduce {
    position: relative;
    width: 100%;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .introduce p {
    position: relative;
    height: auto;
    max-height: 205px;
    font-size: 26px;
    overflow-y: auto;
    font-family: kaiti;
    text-indent: 2em;
    color: white;
    word-break: break-all;
    text-align: justify;
    margin-left: 70px;
    margin-right: 76px;
}

.campus-health .campus-health-container .content .navs {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px;
}

.campus-health .campus-health-container .content .navs .navs-item {
    position: relative;
    width: 114px;
    height: auto;
}

.campus-health .campus-health-container .content .navs .navs-item a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.campus-health .campus-health-container .content .navs .navs-item a img {
    position: relative;
    width: 114px;
    height: 114px;
}

.campus-health .campus-health-container .content .navs .navs-item a .title {
    position: relative;
    font-size: 22px;
    font-family: kaiti;
    color: white;
    margin-top: 15px;
    font-weight: bold;
}
</style>